@media only screen and (min-Width: 900px) {
    .left {
        position: absolute;
        left: 50%;
        top: 50%;
        height: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
}