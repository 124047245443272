.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.8;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}