.affix {
    padding: 0;
    background-color: #2d353c;
}

.menu {
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.menu button:hover {
    color: #00E676;
}