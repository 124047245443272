  // body {
  //   background-image: linear-gradient(to top, #2e1753, #1f1746, #131537, #0d1028, #050819);
  // }
  
  .astronaut img{
    width:100px;
    position:absolute;
    top:55%;
    animation:astronautFly 6s infinite linear;
  }
  @keyframes astronautFly{
    0%{
      left:-100px;
    }
    25%{
      top:50%;
      transform:rotate(30deg);
    }
    50%{
      transform:rotate(45deg);
      top:55%;
    }
    75%{
      top:60%;
      transform:rotate(30deg);
    }
    100%{
      left:110%;
      transform:rotate(45deg);
    }
  }
  @keyframes starTwinkle{
    0%{
       background:rgba(255,255,255,0.4);
    }
    25%{
      background:rgba(255,255,255,0.8);
    }
    50%{
     background:rgba(255,255,255,1);
    }
    75%{
      background:rgba(255,255,255,0.8);
    }
    100%{
      background:rgba(255,255,255,0.4);
    }
  }